/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useState, useRef, useEffect, useContext } from 'react';
import { Form, Button, Tab, Tabs } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import './UserStoryGenerator.css';
import stream from '../../utils/stream';
import llmGuardSubmit from '../../utils/llmGuardSubmit';
import CustomFeedback from '../../components/Feedback';
import Chatbox from '../../components/Chatbox';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import {
  Appl,
  Chat,
  ChatboxInterface,
  ProcessApiPayload,
} from '../../interfaces';
import { Llm_models } from '../../types';
import SelectInstance from '../../components/SelectInstance';
import ContentLoader from '../../components/Loader/contentLoader';
import AdvancedSettings from '../../components/AdvancedSettings';
import ActivityStatusDropDown from '../../components/ActivityStatusDropDown';
import UserContext from '../../context/UserContext';
import {
  Completed_Status,
  CONTRIBUTOR,
  CSV,
  FILE_TYPE_ERROR,
  NORMAL,
  PGVECTOR,
  USER_STORY_GENERATOR,
  XLSXFile,
} from '../../utils/constants';
import { fixXMLTags } from '../../utils/stringProcessing';
import * as XLSX from 'xlsx';
import DebugBox from '../../components/DebugBox';
import ErrorMessage from '../../components/ErrorMessage';
import { isAcceptedFileFormat } from '../../utils/fileTypeCheck';
import AdvSettingsToast from '../../components/ErrorMessage/AdvSettingsToast';
import ToastMessage from '../../components/ErrorMessage/ToastMessage';

const UserStoryGenerator = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [outputData, setOutputData] = useState<string>('');
  const [showFeedbackOptions, setShowFeedBackOptions] =
    useState<boolean>(false);
  const [activityId, setActivityId] = useState<string>('');
  const chatbox = useRef<ChatboxInterface>(null);
  const overlayContainerRef = useRef<HTMLDivElement | null>(null);
  const [showErrMsg, setShowErrMsg] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState('chatbox');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const currentApp = apps.find((app) => app.url === appUrl);
  const [activityResponse, setActivityResponse] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string>('');
  const [selectedHeader, setSelectedHeader] = useState<string>('');
  const [headers, setHeaders] = useState<string[]>([]);
  const [instanceData, setInstanceData] = useState<any>('');
  const [sanitizedPrompt, setSanitizedPrompt] = useState<string>('');
  const [showSanitizedPrompt, setShowSanitizedPrompt] =
    useState<boolean>(false);
  const userRole = JSON.parse(sessionStorage.getItem('role') || '{}');
  const [activeTab, setActiveTab] = useState('Upload');
  const [fileTypeError, setFileTypeError] = useState(false);
  const acceptedFiles = [CSV, XLSXFile];
  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    useAdvancedSettings: false,
    userInput: '',
    setUserInput: () => {},
    isLoading: false,
    setIsLoading: () => {},
    showAdvancedSettings: false,
    setShowAdvancedSettings: () => {},
    showAdvancedSettingsAlert: false,
    setShowAdvancedSettingsAlert: () => {},
    chatContents: [],
    setChatContents: () => [],
    advancedSettings: {
      mode: 'autocut',
      autocut: 1,
      query_param: undefined,
      fusion_type: undefined,
      enableChatRegeneration: false,
      debugMode: false,
    },
    setIsActivityActive: () => {},
    isActivityActive: false,
    showToast: false,
    setShowToast: () => {},
    llmCloud: '',
    filesSelectedForUploadRef: null,
    llmGuardOption: '',
    knowledgeDocumentStatus: '',
    debugMode: false,
    errorMessage: {},
    setErrorMessage: () => {},
    showActivityError: false,
    setShowActivityError: () => {},
  };

  const {
    selectedInstance,
    useAdvancedSettings,
    advancedSettings,
    userInput,
    setUserInput,
    setShowAdvancedSettingsAlert,
    showAdvancedSettingsAlert,
    setShowAdvancedSettings,
    isLoading,
    setIsLoading,
    chatContents,
    setChatContents,
    setIsActivityActive,
    isActivityActive,
    showToast,
    setShowToast,
    llmCloud,
    filesSelectedForUploadRef,
    llmGuardOption,
    knowledgeDocumentStatus,
    debugMode,
    errorMessage,
    setErrorMessage,
    showActivityError,
    setShowActivityError,
  } = userContext;

  const [selectedLlmModel, setSelectedLlmModel] =
    useState<Llm_models>('gpt-4-turbo');
  const abortControllerRef = useRef<AbortController | null>(null);
  useEffect(() => {
    setSelectedLlmModel('gpt-4-turbo');
  }, [llmCloud]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeaders([]);
    const files = event.target.files;
    if (event.target.files && event.target.files[0]) {
      const isValidFormat = isAcceptedFileFormat(
        event.target.files[0],
        acceptedFiles
      );
      if (isValidFormat) {
        setFileTypeError(false);
        setSelectedFile(event.target.files[0]);
        if (files && files.length > 0) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const headerValues: any = [];
            let headerRow = -1;
            for (let row = 0; ; row++) {
              let rowEmpty = true;
              for (let col = 0; ; col++) {
                const cell = XLSX.utils.encode_cell({ r: row, c: col });
                if (!sheet[cell]) {
                  break;
                }
                if (sheet[cell].v && sheet[cell].v.trim() !== '') {
                  rowEmpty = false;
                  break;
                }
              }
              if (!rowEmpty) {
                headerRow = row;
                break;
              }
            }
            if (headerRow !== -1) {
              for (let col = 0; ; col++) {
                const cell = XLSX.utils.encode_cell({ r: headerRow, c: col });
                if (!sheet[cell]) {
                  break;
                }
                headerValues.push(sheet[cell].v);
              }
            }
            setHeaders(headerValues);
          };
          reader.readAsArrayBuffer(file);
        }
      } else {
        setFileTypeError(true);
      }
    }
    // setShowOutputColumns(true);
  };

  useEffect(() => {
    if (
      filesSelectedForUploadRef &&
      filesSelectedForUploadRef.current?.value === ''
    ) {
      setHeaders([]);
      setSelectedHeader('');
    }
  }, [filesSelectedForUploadRef, selectedInstance]);

  const UploadFile = async () => {
    try {
      setShowToast(false);
      const formData = new FormData();
      if (selectedFile) {
        formData.append('file', selectedFile);
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/generation/bulk/upload`,
        formData,
        {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        }
      );
      const responseData = response.data;
      if (
        responseData &&
        responseData.response &&
        responseData.response.file_path
      ) {
        if (selectedInstance) {
          setSelectedFile(null);
        }
        return responseData.response;
      } else {
        console.error('File upload failed.');
        return null;
      }
    } catch (error: any) {
      console.error(
        'Error uploading files:',
        error.response?.data || error.message
      );
      const err = error?.response?.data?.error;
      setShowToast(true);
      setShowErrMsg(err.errorMessage);
      throw error;
    }
  };

  const handlePromptSubmit = async (payload: any, isRegenerate = false) => {
    setInputText(userInput);
    if (
      useAdvancedSettings &&
      advancedSettings.mode !== NORMAL &&
      advancedSettings.query_param === ''
    ) {
      setShowAdvancedSettingsAlert(true);
    } else {
      if (!isRegenerate) {
        setChatContents([
          ...chatContents,
          { role: 'user', content: userInput } as Chat,
          { role: 'assistant', content: '' } as Chat,
        ]);
      } else {
        // Remove the last assistant message
        setChatContents((prevContents) => [
          ...prevContents.slice(0, -1),
          { role: 'assistant', content: '' } as Chat,
        ]);
      }
      setUserInput('');
      setOutputData('');
      setIsLoading(true);
      setShowFeedBackOptions(false);
      abortControllerRef.current = new AbortController();
      stream({
        requestBody: payload,
        streamCallback: (value: string) => {
          // setOutputData((prev) => `${prev}${value}`);
          setOutputData((prev) => {
            setChatContents((oldChatContents) =>
              oldChatContents.map((chatContent, index, array) => {
                if (index + 1 === array.length) {
                  return {
                    ...chatContent,
                    content: fixXMLTags(`${prev}${value}`),
                  };
                } else {
                  return chatContent;
                }
              })
            );
            return `${prev}${value}`;
          });
        },
        doneCallback: () => {
          setIsLoading(false);
          setShowFeedBackOptions(true);
          setShowToast(false);
        },
        setShowToast: setShowToast,
        setIsLoading: setIsLoading,
        setErrorMessage: setErrorMessage,
        llmCloud: llmCloud,
        selectedLlmModel: selectedLlmModel,
        activityId: activityId,
        setActivityId: setActivityId,
        signal: abortControllerRef.current.signal,
      });
    }
  };

  const handleAbortStream = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  const handleLlmGuardSubmit = async (payload: any) => {
    setInputText(userInput);
    if (
      useAdvancedSettings &&
      advancedSettings.mode !== NORMAL &&
      advancedSettings.query_param === ''
    ) {
      setShowAdvancedSettingsAlert(true);
    } else {
      setChatContents([
        ...chatContents,
        { role: 'user', content: userInput } as Chat,
        // { role: 'assistant', content: '' } as Chat,
      ]);
      setUserInput('');
      setOutputData('');
      setIsLoading(true);
      setShowFeedBackOptions(false);
      const response = await llmGuardSubmit({
        requestBody: payload,
        setIsLoading,
        setErrorMessage,
        setActivityId,
        setShowToast,
        llmCloud,
        setSanitizedPrompt,
      });
      if (response) {
        setChatContents((oldChatContents) => [
          ...oldChatContents,
          { role: 'assistant', content: response },
        ]);
        setShowFeedBackOptions(true);
        setShowToast(false);
      }
    }
  };

  const submitPrompt = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowSanitizedPrompt(false);
    setSanitizedPrompt('');
    setShowToast(false);
    event.preventDefault();
    const { enableChatRegeneration, debugMode, ...filteredAdvancedSettings } =
      advancedSettings;
    let payload: any = {
      input_text: chatContents.length === 0 ? userInput : undefined,
      messages:
        chatContents.length !== 0
          ? [
              { role: 'system', content: 'You are a helpful assistant' },
              ...chatContents,
              { role: 'user', content: userInput },
            ]
          : undefined,
      instance_id: selectedInstance,
      include_references: true,
      advance_search_params:
        useAdvancedSettings &&
        advancedSettings.mode !== NORMAL &&
        chatContents.length === 0 &&
        instanceData.vectorDb !== PGVECTOR
          ? filteredAdvancedSettings
          : undefined,
    };
    if (llmGuardOption === 'Yes') {
      handleLlmGuardSubmit(payload);
    } else handlePromptSubmit(payload);
  };

  const submitFile = async () => {
    setIsLoading(true);
    setShowToast(false);
    // setSelectedFile(null);
    // let outputColumnValues: any = [];
    // outputColumnValues.push(outputColumns.map((output_columns) => output_columns.value));
    // setColumnOptions(outputColumnValues);
    try {
      const uploadResponse = await UploadFile();

      if (uploadResponse && uploadResponse.file_path) {
        let processApiPayload: ProcessApiPayload = {
          instance_id: parseInt(selectedInstance),
          filepath: uploadResponse.file_path,
          model_params: {
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
          },
          // output_columns:  outputColumnValues?.[0]?.[0] !== '' ? outputColumnValues?.[0] : undefined,
          include_references: true,
          target_column: selectedHeader,
          target_format: uploadResponse.file_ext,
          generator_type: USER_STORY_GENERATOR,
          advance_search_params:
            useAdvancedSettings &&
            advancedSettings.mode !== NORMAL &&
            instanceData.vectorDb !== PGVECTOR
              ? advancedSettings
              : undefined,
        };

        await handleFileProcess(processApiPayload);
      } else {
        setIsLoading(false);
        console.error('File upload failed.');
      }
    } catch (error) {
      setIsLoading(false);
      // setOutputColumns([{ value: '' }]);
      // setColumnOptions(undefined);
      console.error('Error during file upload and processing:', error);
    }
  };

  const handleFileProcess = async (payload: ProcessApiPayload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/generation/bulk/process/async`,
        payload
      );
      setIsLoading(false);
      setHeaders([]);
      setSelectedHeader('');
      // createActivityId();
      setActivityResponse((prev: any) => [...prev, response.data.response]);
    } catch (error: any) {
      const err = error?.response?.data?.error;
      setIsLoading(false);
      setShowToast(true);
      setShowErrMsg(err.errorMessage);
      console.error('Error calling process API:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | React.MouseEvent) => {
      if (
        overlayContainerRef.current &&
        !overlayContainerRef.current.contains(event.target as Node)
      ) {
        setShowAdvancedSettings(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Create Activity ID only when chat loading animation stops
  // useEffect(() => {
  //   if (!isLoading && chatContents.length) {
  //     createActivityId();
  //   }
  // }, [isLoading]);

  const chatClearCallback = () => {
    setShowToast(false);
    setChatContents([]);
    setShowSanitizedPrompt(false);
    setSanitizedPrompt('');
  };

  const handleActivitySelect = (selectedActivity: any) => {};

  const handleRadioChange = (selectedHeader: string) => {
    setSelectedHeader(selectedHeader);
  };

  const handleRegenerate = async (content: string) => {
    setIsLoading(true);
    const { enableChatRegeneration, debugMode, ...filteredAdvancedSettings } =
      advancedSettings;
    const payload = {
      input_text:
        chatContents.length <= 2
          ? chatContents[chatContents.length - 2].content
          : undefined,
      messages:
        chatContents.length > 2
          ? [
              { role: 'system', content: 'You are a helpful assistant' },
              ...chatContents.slice(0, -2),
              {
                role: 'user',
                content: chatContents[chatContents.length - 2].content,
              },
            ]
          : undefined,
      instance_id: selectedInstance,
      include_references: true,
      advance_search_params:
        useAdvancedSettings &&
        advancedSettings.mode !== NORMAL &&
        chatContents.length <= 2 &&
        instanceData.vectorDb !== PGVECTOR
          ? filteredAdvancedSettings
          : undefined,
    };
    if (llmGuardOption === 'Yes') {
      await handleLlmGuardSubmit(payload);
    } else {
      await handlePromptSubmit(payload, true); // Passing additional parameter to indicate regeneration
    }
  };

  return (
    <div className="application-page-container">
      <div className="title">{currentApp?.name}</div>
      <div className="back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back
        </Link>
        <div ref={overlayContainerRef}>
          <AdvancedSettings
            chatbox={chatbox}
            overlayContainerRef={overlayContainerRef}
          />
        </div>
      </div>
      <div className="single-app-container">
        <SelectInstance
          projectId={projectId}
          currentApp={currentApp}
          chatbox={chatbox}
          setOutputData={setOutputData}
          setInstanceDetails={setInstanceData}
          instanceDetails={instanceData}
          // chatContents={chatContents}
        />

        <Form.Group
          controlId="exampleForm.optionPicker"
          className={
            userRole === CONTRIBUTOR ? 'user-input margin-top' : 'user-input'
          }
        >
          {currentApp && currentApp.url === 'user-story-generator' && (
            <div className="option-labels">
              <span
                className={
                  selectedOption === 'fileUpload' ? 'disable-text' : ''
                }
              >
                Chatbox
              </span>
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={selectedOption === 'fileUpload'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedOption(
                    event.target.checked ? 'fileUpload' : 'chatbox'
                  );
                  setShowToast(false);
                  setShowFeedBackOptions(false);
                }}
              />
              <span
                className={
                  selectedOption === 'fileUpload' ? '' : 'disable-text'
                }
              >
                File Upload
              </span>
            </div>
          )}
          {currentApp &&
          currentApp.url === 'user-story-generator' &&
          selectedOption === 'fileUpload' ? (
            <Tabs
              defaultActiveKey="Upload"
              id="justify-tab-example"
              className="mb-3"
              justify
              onSelect={(key: string | null) => {
                if (key !== null) {
                  setActiveTab(key);
                  setIsActivityActive(!isActivityActive);
                }
              }}
            >
              <Tab eventKey="Upload" title="Upload">
                <Form.Group
                  className="file-input"
                  controlId="exampleForm.fileInput"
                >
                  <Form.Label>Upload document</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".csv, .xlsx"
                    onChange={handleFileChange}
                    ref={filesSelectedForUploadRef}
                    disabled={
                      !selectedInstance ||
                      (selectedInstance &&
                        knowledgeDocumentStatus !== Completed_Status)
                    }
                  />
                  {fileTypeError && (
                    <ToastMessage
                      setShowToast={setFileTypeError}
                      isInsideModal={false}
                      errorMessage={`${FILE_TYPE_ERROR} ${CSV}, ${XLSXFile}.`}
                    />
                  )}
                  <div>
                    <Form.Text>Formats supported: xlsx & csv</Form.Text>
                  </div>
                  <div>
                    <Form.Text>
                      Only 25 requirements are supported in a batch for
                      processing.
                    </Form.Text>
                  </div>
                  <div>
                    <Form.Text>
                      Once file is uploaded, select the requirement's column to
                      be used for generating user stories.
                    </Form.Text>
                  </div>
                  <div className="mb-3" style={{ marginTop: '18px' }}>
                    <Form>
                      {headers.map((header, index) => (
                        <div key={index}>
                          <Form.Check
                            type="radio"
                            id={`radio-${index}`}
                            label={header}
                            checked={selectedHeader === header}
                            onClick={() => handleRadioChange(header)}
                            value={header}
                          />
                        </div>
                      ))}
                    </Form>
                  </div>
                  {isLoading ? (
                    <Button
                      className="user-story-generator-submit-button"
                      disabled={true}
                      style={{ width: '104px', height: '36px' }}
                    >
                      <ContentLoader />
                    </Button>
                  ) : (
                    <>
                      <Button
                        className="user-story-generator-submit-button"
                        as="input"
                        type="submit"
                        value="Submit"
                        onClick={submitFile}
                        disabled={selectedHeader.length > 0 ? false : true}
                      />
                    </>
                  )}
                </Form.Group>
              </Tab>
              <Tab eventKey="Activity" title="Activity">
                <div>
                  <ActivityStatusDropDown
                    activityResponse={activityResponse}
                    setIsDisabled={setIsDisabled}
                    activityType={USER_STORY_GENERATOR}
                    onActivitySelect={handleActivitySelect}
                    isC2D={false}
                  />
                </div>
              </Tab>
            </Tabs>
          ) : (
            <Chatbox
              submitPrompt={submitPrompt}
              chatClearCallback={chatClearCallback}
              ref={chatbox}
              selectedModel={llmCloud}
              useMarkdownFormat={instanceData.renderMarkdown}
              handleAbortStream={handleAbortStream}
              handleRegenerate={handleRegenerate}
            />
          )}
        </Form.Group>
      </div>
      {debugMode && (
        <div className="single-app-container">
          <DebugBox
            activityId={activityId}
            isChatLoading={isLoading}
            chatContents={chatContents}
          />
        </div>
      )}
      {selectedOption === 'chatbox' && (
        <>
          {llmGuardOption === 'Yes' &&
            sanitizedPrompt &&
            chatContents?.length > 0 && (
              <Form.Group className="transcript-toggle-container">
                <Form.Check
                  type="switch"
                  id="transcript-view-switch"
                  label=" View Sanitized Prompt"
                  onChange={(evt) => setShowSanitizedPrompt(evt.target.checked)}
                />
              </Form.Group>
            )}
          {showSanitizedPrompt && (
            <Form.Group controlId="outputTextArea">
              <Form.Control
                as="textarea"
                rows={10}
                className="output-container"
                value={`Sanitized Prompt:\n${sanitizedPrompt}`}
                disabled
              />
            </Form.Group>
          )}
        </>
      )}
      {showToast && (
        <ErrorMessage errorMessage={errorMessage} setShowToast={setShowToast} />
      )}
      {showActivityError && activeTab === 'Activity' && (
        <ErrorMessage
          errorMessage={errorMessage}
          setShowToast={setShowActivityError}
        />
      )}
      {chatContents.length > 0 && showFeedbackOptions && (
        <CustomFeedback
          url={`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/feedback/update`}
          activityId={activityId}
        />
      )}
      {showAdvancedSettingsAlert && (
        <AdvSettingsToast
          setShowAdvancedSettingsAlert={setShowAdvancedSettingsAlert}
        />
      )}
    </div>
  );
};

export default UserStoryGenerator;
