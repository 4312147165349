import React, { useContext, useEffect, useState } from 'react';
import { Button, Table, Badge, Spinner, Modal } from 'react-bootstrap';
import './KnowledgeIdTable.css';
import { FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import { ADMIN, PGVECTOR, SUPERADMIN } from '../../utils/constants';
import UserContext from '../../context/UserContext';
import ErrorMessage from '../ErrorMessage';

interface KnowledgeTableProps {
  data: any[];
  expandedRows: Set<number>;
  toggleRowExpansion: (rowIndex: number) => void;
  selectedInstance?: string;
  getInstanceData?: any;
  showDelete?: boolean;
  cloudProvider?: any;
  vectorDb?: string;
}

const KnowledgeTable: React.FC<KnowledgeTableProps> = ({
  data,
  expandedRows,
  toggleRowExpansion,
  selectedInstance,
  getInstanceData,
  showDelete,
  cloudProvider,
  vectorDb,
}) => {
  const [isDeleteResponse, setIsDeleteResponse] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [knowledgePaylaod, setknowledgePaylaod] = useState<any>({});
  const [knowledgeQueryParams, setknowledgeQueryParams] = useState<any>({});
  const [docName, setDocName] = useState<string>('');
  const userRole = JSON.parse(sessionStorage.getItem('role') || '{}');
  const userContext = useContext(UserContext) || {
    showToast: false,
    setShowToast: () => {},
    errorMessage: {},
    setErrorMessage: () => {},
  };

  const { showToast, setShowToast, errorMessage, setErrorMessage } =
    userContext;

  const handleKnowledgeDelete = async (queryParams: any, payload: any) => {
    setIsDeleteLoading(true);
    const weaviateUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v2/ui/weaviate/delete-knowledge`;
    try {
      let response;
      response = await axios.delete(weaviateUrl, {
        params: queryParams,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setIsDeleteLoading(false);
        setIsDeleteResponse(true);
        setShowDeletePopup(false);
        getInstanceData(selectedInstance);
      } else {
        console.error('Failed to delete file');
        setIsDeleteLoading(false);
      }
    } catch (error: any) {
      const err = error?.response?.data?.error;
      const errID = error?.response?.data?.errorId;
      setShowToast(true);
      setErrorMessage({
        message: err.errorMessage,
        id: errID,
      });
      console.error('Error:', error);
      setIsDeleteLoading(false);
    }
  };

  useEffect(() => {
    const removeSuccessMessage = setTimeout(() => {
      setIsDeleteResponse(false);
    }, 2000);

    return () => clearTimeout(removeSuccessMessage);
  }, [isDeleteResponse]);

  const handleDelete = async (
    KnowledgeId: string,
    docId: string,
    documentName: string
  ) => {
    setIsDeleteResponse(false);
    let queryParams = {};
    let payload = {};
    if (vectorDb === PGVECTOR) {
      payload = {
        instance_id: selectedInstance,
        knowledge_id: KnowledgeId,
        collection_name: 'AzureDocument',
        document_name: documentName,
      };
    } else {
      queryParams = {
        document_id: docId,
      };
    }
    // handleKnowledgeDelete(payload);
    setknowledgeQueryParams(queryParams);
    setknowledgePaylaod(payload);
    setDocName(documentName);
    setShowDeletePopup(true);
  };
  const canceldeletePopup = () => {
    setShowDeletePopup(false);
  };

  const deleteModal = () => {
    return (
      <div className="clear-chat-warning-container">
        <Modal
          show={showDeletePopup}
          onHide={canceldeletePopup}
          backdrop={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Knowledge Document(s)?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-warning">
              <p>
                This action will delete the knowledge document {docName}. Are
                you sure you want to delete it?
              </p>
            </div>
          </Modal.Body>

          <Modal.Footer>
            {userRole && (userRole === ADMIN || userRole === SUPERADMIN) && (
              <Button
                variant="danger"
                onClick={() =>
                  handleKnowledgeDelete(knowledgeQueryParams, knowledgePaylaod)
                }
              >
                Delete
              </Button>
            )}
            <Button variant="secondary" onClick={canceldeletePopup}>
              Cancel
            </Button>
          </Modal.Footer>
          {showToast && (
            <ErrorMessage
              errorMessage={errorMessage}
              setShowToast={setShowToast}
              isInsideModal={true}
            />
          )}
        </Modal>
      </div>
    );
  };

  return (
    <div className="table-responsive">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="id-column">ID</th>
            <th className="name-column">Name</th>
            <th className="documents-column">Document(s) Name</th>
            {showDelete &&
              userRole &&
              (userRole === ADMIN || userRole === SUPERADMIN) && (
                <th className="delete-column">Actions</th>
              )}
          </tr>
        </thead>
        <tbody>
          {data?.map((edge: any, rowIndex: number) => (
            <React.Fragment key={edge.id}>
              {edge.documentDetails?.map((doc: any, docIndex: number) => (
                <tr
                  key={`${edge.id}-${doc.documentId}`}
                  onClick={() => toggleRowExpansion(rowIndex)}
                  style={{ cursor: 'pointer' }}
                >
                  {docIndex === 0 && (
                    <>
                      <td rowSpan={edge.documentDetails.length}>{edge.id}</td>
                      <td rowSpan={edge.documentDetails.length}>{edge.name}</td>
                    </>
                  )}
                  <td>{doc.documentName}</td>
                  {showDelete &&
                    userRole &&
                    (userRole === ADMIN || userRole === SUPERADMIN) && (
                      <td>
                        <Button
                          className="knowledge-delete-button"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleDelete(
                              edge.id,
                              doc.documentId,
                              doc.documentName
                            )
                          }
                        >
                          <FaTrashAlt className="knowledge-icon-delete" />
                        </Button>
                      </td>
                    )}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      {showDeletePopup && deleteModal()}
      {isDeleteLoading && (
        <div className="upload-spinner">
          <Spinner animation="border" size="sm" /> Deleting..
        </div>
      )}
      {isDeleteResponse && (
        <div className="upload-msg">
          <Badge bg="danger">Delete Successful</Badge>
        </div>
      )}
    </div>
  );
};

export default KnowledgeTable;
