import React, { useEffect, useRef, useState } from 'react';
import './CustomDropDown.css';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

type CustomSearchableDropDownProps = {
  instances: Instance[];
  selectedInstance: number;
  handleInstanceChange: any;
};

type Instance = {
  id: number;
  name: string;
  cloudEnvironment: string;
};

const CustomSearchableDropDown: React.FC<CustomSearchableDropDownProps> = ({
  instances,
  selectedInstance,
  handleInstanceChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const filteredInstances = instances.filter((instance) =>
    instance.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownToggele = () => {
    setIsOpen(!isOpen);
    setSearchTerm('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const handleSelect = (id: number) => {
    // console.log('id', id);
    handleInstanceChange(id);
    setIsOpen(false);
    setSearchTerm('');
  };
  const getSelectedInstanceValue = (defaultText: string) => {
    if (selectedInstance) {
      const inst = instances.find(
        (instance) => instance.id === selectedInstance
      );
      return inst
        ? `ID: ${inst.id} | ${inst.name} | ${inst.cloudEnvironment.toUpperCase()}`
        : ' ';
    }
    return defaultText;
  };

  return (
    <div className="custom-dropdown-container" ref={dropdownRef}>
      <button onClick={handleDropdownToggele}>
        <div className="custom-dropdown-toggle">
          <div className="custom-dropdown-search-container">
            <input
              ref={inputRef}
              type="text"
              className="custom-dropdown-search"
              placeholder={getSelectedInstanceValue('Search here')}
              value={
                isOpen
                  ? searchTerm
                  : getSelectedInstanceValue('Select an instance')
              }
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="custom-dropdown-toggleIcon">
            <span>|</span> {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
      </button>
      {isOpen && (
        <div className="custom-dropdown-options">
          <div className="custom-dropdpowm-options-container">
            {/* <div className="custom-dropdown-items">Select an instance</div> */}
            {filteredInstances.length > 0 ? (
              filteredInstances.map((instance) => (
                <div
                  key={instance.id}
                  className={
                    selectedInstance === instance.id
                      ? 'custom-dropdown-items-selected'
                      : 'custom-dropdown-items'
                  }
                  onClick={() => handleSelect(instance.id)}
                >
                  {`ID: ${instance.id} | ${instance.name} | ${instance.cloudEnvironment.toUpperCase()}`}
                </div>
              ))
            ) : (
              <div className="custom-dropdown-items">
                <span style={{ marginRight: '2px' }}>
                  {' '}
                  {searchTerm ? `No instance found for -` : 'No instance found'}
                </span>
                <b>{searchTerm}</b>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSearchableDropDown;
