import Card from 'react-bootstrap/Card';
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as MichiganLogo } from '../../assets/dumy-michigan-logo.svg';
import { Project } from '../../interfaces';
import Loader from '../../components/Loader';
import './Home.css';
import axios from 'axios';
import UserContext from '../../context/UserContext';
import ErrorMessage from '../../components/ErrorMessage';

const Home = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const userContext = useContext(UserContext) || {
    setErrorMessage: () => {},
    errorMessage: { message: '', id: '' },
  };

  const { setErrorMessage, errorMessage } = userContext;

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/projects/all`
      );

      const data = response.data;
      setShowToast(false);
      setProjects(data.response);
      sessionStorage.setItem('projects', JSON.stringify(data.response));
      setIsLoading(false);
    } catch (error: any) {
      console.error(
        'Error fetching projects:',
        error?.response?.data ?? error?.message
      );
      const err = error?.response?.data?.error?.errorMessage;
      const errID = error?.response?.data?.errorId;
      setIsLoading(false);
      setShowToast(true);
      setErrorMessage({ message: err, id: errID });
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div className="home-page-container">
      <div className="title-container">
        <h1 className="title">Your Projects</h1>
      </div>
      {isLoading ? (
        <Loader />
      ) : projects.length === 0 ? (
        <div style={{ textAlign: 'center' }}>
          <h1>You do not have access to any of the projects.</h1>
          <p>
            Please reach out to your sector’s Nerve Center for more information
            on gaining access.
          </p>
        </div>
      ) : (
        <div className="card-container">
          {projects.length > 0 &&
            projects.map((project) => (
              <Link
                to={`/project/${project.id.toString()}`}
                className="card-link home-card-link"
                key={project.id}
              >
                <Card className="project-card-container">
                  <Card.Body className="project-card-body" title={project.name}>
                    <Card.Title className="project-card-title">
                      <div className="project-logo-container">
                        <MichiganLogo />
                      </div>
                      <h2
                        className={`card-title-text ${project.name?.length > 40 ? 'long-project-text' : 'project-text'}`}
                      >
                        {project.name}
                      </h2>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            ))}
        </div>
      )}
      {showToast && (
        <ErrorMessage errorMessage={errorMessage} setShowToast={setShowToast} />
      )}
    </div>
  );
};

export default Home;
