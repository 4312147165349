import React, { useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import './ErrorMessage.css';

interface ErrorMessageProps {
  errorMessage: string;
  setShowToast?: any;
  isInsideModal?: boolean;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  errorMessage,
  setShowToast,
  isInsideModal,
}) => {
  useEffect(() => {
    if (setShowToast) {
      const timer = setTimeout(() => setShowToast(false), 15000);
      return () => clearTimeout(timer);
    }
  }, [setShowToast]);

  return (
    <div className="toast-container">
      <Toast
        onClose={() => (setShowToast ? setShowToast(false) : {})}
        show={true}
        delay={15000}
        autohide
        className={`bg-danger text-white ${isInsideModal ? 'modal-toast' : 'regular-toast'} custom-toast-width`}
      >
        <Toast.Header className="bg-danger text-white close-icon"></Toast.Header>
        <Toast.Body>{errorMessage}</Toast.Body>
      </Toast>
    </div>
  );
};

export default ErrorMessage;
