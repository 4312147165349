import React from 'react';
import {
  Chat,
  ErrorMessageTypes,
  IAdvancedSettings,
  OutputColumn,
} from '../interfaces';
import { SetStateFunction } from '../types';

export interface UserContextType {
  selectedInstance: any;
  setSelectedInstance: React.Dispatch<any>;
  useAdvancedSettings: boolean;
  setUseAdvancedSettings: React.Dispatch<boolean>;
  setAdvancedSettings: SetStateFunction<IAdvancedSettings>;
  advancedSettings: IAdvancedSettings;
  showToast: boolean;
  setShowToast: React.Dispatch<boolean>;
  showErrMsg: string;
  setShowErrMsg: React.Dispatch<string>;
  chatContents: Chat[];
  setChatContents: SetStateFunction<Chat[]>;
  userInput: string;
  setUserInput: (userInput: string) => void;
  setShowAdvancedSettingsAlert: SetStateFunction<boolean>;
  showAdvancedSettingsAlert: boolean;
  showAdvancedSettings: boolean;
  setShowAdvancedSettings: SetStateFunction<boolean>;
  isLoading: boolean;
  setIsLoading: SetStateFunction<boolean>;
  localSelectedModel: string;
  setLocalSelectedModel: SetStateFunction<string>;
  isActivityActive: boolean;
  setIsActivityActive: SetStateFunction<boolean>;
  isRefreshing: boolean;
  setIsRefreshing: SetStateFunction<boolean>;
  setOutputData: SetStateFunction<string>;
  outputData: string;
  queryExpansion: boolean;
  setQueryExpansion: SetStateFunction<boolean>;
  llmCloud: string;
  setLlmCloud: SetStateFunction<string>;
  cloudProvider: string;
  setCloudProvider: SetStateFunction<string>;
  filesSelectedForUploadRef: React.RefObject<HTMLInputElement>;
  llmGuardOption: string;
  setLlmGuardOption: SetStateFunction<string>;
  outputColumns: OutputColumn[];
  setOutputcolumns: SetStateFunction<OutputColumn[]>;
  knowledgeDocumentStatus: string;
  setKnowledgeDocumentStatus: SetStateFunction<string>;
  showKnowledgeDocumentStatus: boolean;
  setShowKnowledgeDocumentStatus: SetStateFunction<boolean>;
  enableChatRegeneration: boolean;
  setEnableChatRegeneration: SetStateFunction<boolean>;
  debugMode: boolean;
  setDebugMode: SetStateFunction<boolean>;
  errorMessage: ErrorMessageTypes;
  setErrorMessage: SetStateFunction<ErrorMessageTypes>;
  showErrorDetails: boolean;
  setShowErrorDetails: SetStateFunction<boolean>;
  showActivityError: boolean;
  setShowActivityError: SetStateFunction<boolean>;
}

const UserContext = React.createContext<UserContextType | null>(null);

export default UserContext;
