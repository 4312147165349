import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { SetStateFunction } from '../../types';
import './ActivityStatusDropDown.css';
import UserContext from '../../context/UserContext';
import { IoMdRefresh } from 'react-icons/io';
import { USER_STORY_GENERATOR } from '../../utils/constants';
type ActivityStatusDropDownProps = {
  activityResponse: any;
  setIsDisabled?: SetStateFunction<boolean>;
  activityType?: string;
  setMeetingSummarizerUserInput?: any;
  onActivitySelect?: any;
  setActiveActivityId?: SetStateFunction<string>;
  setOutputData?: SetStateFunction<string>;
  isAssistant?: any;
  isC2D?: boolean;
};
const ActivityStatusDropDown: React.FC<ActivityStatusDropDownProps> = ({
  activityResponse,
  setIsDisabled,
  activityType,
  setMeetingSummarizerUserInput,
  onActivitySelect,
  setActiveActivityId,
  setOutputData,
  isAssistant,
  isC2D,
}) => {
  const [activityStatus, setActivityStatus] = useState<any>({});
  const [activeId, setActiveId] = useState<string>('');
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const [selectActivity, setSelectActivity] = useState<string>('');
  const [listActivity, setListActivity] = useState<any>([]);
  const [downloadResponseLink, setDownloadResponseLink] = useState<string>('');
  const [isDownload, setIsDownload] = useState<boolean>(!isC2D ? true : false);
  const [loading, setLoading] = useState<boolean>(false);
  const { projectId } = useParams();
  const userContext = useContext(UserContext) || {
    userInput: '',
    setUserInput: () => {},
    isActivityActive: false,
    setIsActivityActive: () => {},
    setIsRefreshing: () => {},
    isRefreshing: true,
    llmCloud: '',
    setErrorMessage: () => {},
    setShowActivityError: () => {},
  };

  const {
    setUserInput,
    isActivityActive,
    setIsActivityActive,
    setIsRefreshing,
    setShowActivityError,
    isRefreshing,
    llmCloud,
    setErrorMessage,
  } = userContext;

  const fetchActivity = async () => {
    setShowActivityError(false);
    if (setMeetingSummarizerUserInput) {
      setMeetingSummarizerUserInput('');
    }
    const queryParams = {
      project_id: projectId,
      type: activityType,
    };
    let api;
    const allActivityApi = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/all`;
    api = allActivityApi;
    const fetchresponse = await axios.get(api, {
      params: queryParams,
    });
    const data = await fetchresponse.data;
    setListActivity(data.response.edges);
    const filteredEdges = data.response?.edges?.filter(
      (data: any) =>
        data?.node?.metaData &&
        Array.isArray(data?.node?.metaData) &&
        data?.node?.metaData !== null &&
        data?.node?.metaData.some((meta: any) => Object.keys(meta).length > 0)
    );
    const res = filteredEdges.slice(-1);
    setSelectActivity(res[0]?.node?.id);
    onActivitySelect(res[0]?.node?.id);
    setLoading(false);
  };

  useEffect(() => {
    if (activityType) {
      setLoading(true);
      fetchActivity();
    }
  }, [activityResponse]);

  useEffect(() => {
    if (selectActivity) {
      setIsRefreshing(true);
    }
  }, [selectActivity]);

  const handleCheckActivity = (value: string) => {
    setSelectActivity(value);
    onActivitySelect(value);
    setShowStatus(false);
  };

  useEffect(() => {
    if (selectActivity && listActivity.length > 0) {
      let selectedActId;
      selectedActId = listActivity.find(
        (data: any) => data?.node?.id === selectActivity
      );

      if (selectedActId) {
        getActivityStatus(selectedActId);
      }
    }
  }, [selectActivity, isActivityActive]);

  const getActivityStatus = async (actId: any) => {
    try {
      if (setOutputData) {
        setOutputData('');
      }
      setIsRefreshing(true);
      setDownloadResponseLink('');
      if (setIsDisabled) {
        setIsDisabled(true);
      }
      setUserInput('');
      if (setMeetingSummarizerUserInput) {
        setMeetingSummarizerUserInput('');
      }
      let activityId;
      let taskId;
      activityId = actId?.node?.id;
      taskId = actId?.node?.taskId;

      setActiveId(taskId);
      const bulk_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/generation/bulk/process/task-by-activityid/${activityId}`;
      let url = '';
      let formData: { [key: string]: any } = {};

      if (activityType === USER_STORY_GENERATOR) {
        url = bulk_api;
      }
      if (url) {
        let response;
        response = await axios.get(url, {
          params: formData,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          setShowActivityError(false);
          setIsRefreshing(false);
          const newStatus = { ...activityStatus };
          newStatus[taskId] = response.data.response.status;

          let outputData;
          outputData = response.data?.response.output;
          if (newStatus[taskId] === 'Failed') {
            setShowActivityError(true);
            const errorMessage =
              'Your request could not be completed successfully.';
            const errID = response.data?.response?.errorId;
            setErrorMessage({
              message: errorMessage,
              id: errID,
            });
          }
          setDownloadResponseLink(response?.data?.response?.download_link);
          if (setOutputData) {
            setOutputData(outputData);
          }
          setActivityStatus(newStatus);
          if (setActiveActivityId) {
            setActiveActivityId(activityId);
          }
          setShowStatus(true);
          if (newStatus[taskId] === 'Complete') {
            setMeetingSummarizerUserInput &&
              setMeetingSummarizerUserInput(
                response?.data?.response?.rawTranscription
              );
            if (setIsDisabled) {
              setIsDisabled(false);
            }
          }
        }
      }
    } catch (error) {
      console.error('response after check activity', error);
      setShowStatus(false);
      if (setIsDisabled) {
        setIsDisabled(true);
      }
      setIsRefreshing(false);
    }
  };

  return (
    <>
      <Form.Group className="user-input" controlId="exampleForm.instancePicker">
        <Form.Label>Activity List</Form.Label>
        <Form.Select
          aria-label="Activity status"
          className="instance-picker"
          value={selectActivity}
          onChange={(evt) => {
            const selectedValue = evt.target.value;
            handleCheckActivity(selectedValue);
          }}
        >
          {loading ? (
            <option>Loading...</option>
          ) : (
            <>
              {listActivity && (
                <>
                  {listActivity
                    .slice()
                    .reverse()
                    .filter((data: any) => {
                      const metaData = data?.node?.metaData;
                      const isMetaDataArray =
                        Array.isArray(metaData) && metaData !== null;
                      if (!isMetaDataArray) return false;
                      return metaData.some(
                        (meta: any) =>
                          Object.keys(meta).length > 0 &&
                          meta.hasOwnProperty('file_name')
                      );
                    })
                    ?.map((data: any, index: any) => {
                      const actId = data?.node;
                      return (
                        <option key={index} value={actId?.id}>
                          {actId?.id} -{' '}
                          {actId?.metaData?.map((item: any, index: any) => (
                            <>
                              <div key={index}>{item.file_name}</div>
                            </>
                          ))}
                        </option>
                      );
                    })}
                </>
              )}
            </>
          )}
        </Form.Select>
        {!loading && showStatus ? (
          <Form.Label style={{ marginTop: '1rem' }}>
            Status :{' '}
            {activityStatus[activeId] === 'Complete' ? (
              <Badge bg="success">{activityStatus[activeId]}</Badge>
            ) : (
              <>
                <Badge bg="warning" text="dark" style={{ marginRight: '8px' }}>
                  {activityStatus[activeId]}
                </Badge>
                {!isRefreshing ? (
                  <IoMdRefresh
                    onClick={() => setIsActivityActive(!isActivityActive)}
                  />
                ) : (
                  <div>Refreshing...</div>
                )}
              </>
            )}
          </Form.Label>
        ) : (
          <Form.Label style={{ marginTop: '1rem' }}>
            Status : Loading..
          </Form.Label>
        )}
        {!loading && downloadResponseLink && (
          <a
            className="download-button-tag user-story-generator-submit-button"
            href={`${downloadResponseLink}`}
            target="_blank"
            rel="noreferrer"
          >
            Download File
          </a>
        )}
      </Form.Group>
    </>
  );
};

export default ActivityStatusDropDown;
