/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Instance } from '../../interfaces';
import { Alert, Badge, Button, Form, Modal, Spinner } from 'react-bootstrap';
import InstanceGenerator from '../InstanceGenerator';
import UserContext from '../../context/UserContext';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import './SelectInstance.css';
import { FaTrashAlt } from 'react-icons/fa';
import { SetStateFunction } from '../../types';
import {
  ADMIN,
  Completed_Message,
  Completed_Status,
  Failed_Message,
  Failed_Message_Support,
  Failed_Message_Support_one,
  Failed_Message_Support_two,
  Failed_Status,
  GPT35_16K,
  GPT4_32K,
  GPT_4O,
  InProgress_Status,
  Pending_Message,
  SUPERADMIN,
} from '../../utils/constants';
import { IoMdRefresh } from 'react-icons/io';
import ContentLoader from '../Loader/contentLoader';
import CustomSearchableDropDown from '../CustomSearchableDropDown/ index';
import ErrorMessage from '../ErrorMessage';

type SelectInstanceProps = {
  projectId: any;
  currentApp: any;
  step2?: boolean;
  step3?: boolean;
  step4?: boolean;
  chatbox?: any;
  isUploadtemplate?: boolean;
  outputData?: any;
  setOutputData?: any;
  setShowDownload?: SetStateFunction<boolean>;
  setUploadedFiles?: SetStateFunction<File | null>;
  instanceDetails?: any;
  setInstanceDetails?: any;
  setisUploadingProps?: SetStateFunction<boolean>;
  setIsUploadResponseProps?: SetStateFunction<boolean>;
  clearSelectedFile?: Function;
};
type DeleteErrorToast = {
  show: boolean;
  message: string;
};

const SelectInstance: React.FC<SelectInstanceProps> = ({
  projectId,
  step2 = true,
  step3 = true,
  step4 = true,
  currentApp,
  chatbox,
  isUploadtemplate,
  outputData,
  setOutputData,
  setShowDownload,
  setUploadedFiles,
  instanceDetails,
  setInstanceDetails,
  setisUploadingProps,
  setIsUploadResponseProps,
  clearSelectedFile,
}) => {
  const [instances, setInstances] = useState<Instance[]>([]);
  const [showNewInstanceModal, setShowNewInstanceModal] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isEditMode, setIsEditMode] = useState(false);
  // const [instanceDetails, setInstanceDetails] = useState<string>('');
  const [showClearChatWarning, setShowClearChatWarning] =
    useState<boolean>(false);
  const [newSelectedInstance, setNewSelectedInstance] = useState<any>(null);
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isDeleteResponse, setIsDeleteResponse] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [isDeleteFailed, setIsDeleteFailed] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<DeleteErrorToast>({
    show: false,
    message: '',
  });
  const [isInstanceInvalid, setIsInstanceInvalid] = useState<boolean>(false);
  const [refreshKnowledgeDocumentStatus, setRefreshKnowledgeDocumentStatus] =
    useState<boolean>(false);
  const [isRefreshedClicked, setIsRefreshedClicked] = useState<boolean>(false);
  const [knowledgeErrorId, setKnowledgeErrorId] = useState<string>('');
  const [instanceDataLoading, setInstanceDataLoading] =
    useState<boolean>(false);
  const userRole = JSON.parse(sessionStorage.getItem('role') || '{}');
  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    setSelectedInstance: () => {},
    showToast: false,
    setShowToast: () => {},
    showErrMsg: '',
    chatContents: [],
    setLlmCloud: () => {},
    llmCloud: '',
    setCloudProvider: () => {},
    filesSelectedForUploadRef: null,
    llmGuardOption: '',
    setLlmGuardOption: () => {},
    setChatContents: () => {},
    setIsLoading: () => {},
    setUserInput: () => {},
    knowledgeDocumentStatus: '',
    setKnowledgeDocumentStatus: () => {},
    setShowKnowledgeDocumentStatus: () => {},
    showKnowledgeDocumentStatus: false,
    setQueryExpansion: () => {},
    outputColumns: [{ value: '' }],
    setOutputcolumns: () => {},
    errorMessage: {},
    setErrorMessage: () => {},
  };

  const {
    selectedInstance,
    setSelectedInstance,
    showToast,
    setShowToast,
    showErrMsg,
    chatContents,
    setLlmCloud,
    setCloudProvider,
    filesSelectedForUploadRef,
    llmGuardOption,
    setLlmGuardOption,
    setChatContents,
    setIsLoading,
    setUserInput,
    knowledgeDocumentStatus,
    setKnowledgeDocumentStatus,
    setShowKnowledgeDocumentStatus,
    showKnowledgeDocumentStatus,
    setQueryExpansion,
    outputColumns,
    setOutputcolumns,
    errorMessage,
    setErrorMessage,
  } = userContext;

  const fetchInstances = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/project/${projectId}/application/${currentApp?.id}`
      )
      .then((response) => {
        const data = response.data;
        const sorteddata = data.response.sort((a: any, b: any) => {
          if (Number(a.id) > Number(b.id)) {
            return 1;
          }
          if (Number(a.id) < Number(b.id)) {
            return -1;
          }
          return 0;
        });
        setInstances(sorteddata);
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  };

  useEffect(() => {
    fetchInstances();
    if (selectedInstance) {
      getInstanceData(selectedInstance);
    }
  }, []);

  const handleInstanceChange = (value: string, forceClear: boolean = false) => {
    setShowKnowledgeDocumentStatus(false);
    setKnowledgeDocumentStatus('');
    setIsInstanceInvalid(false);
    setInstanceDataLoading(true);
    if (!forceClear && chatContents && chatContents.length !== 0) {
      chatbox?.current?.initiateChatWarningModal({
        callback: handleInstanceChange,
        prop: value,
      });
    } else if (outputData && outputData?.length !== 0) {
      setNewSelectedInstance(value);
      setShowClearChatWarning(true);
    } else {
      setSelectedInstance(value);
      if (value) {
        getInstanceData(value);
      }
      setOutputData('');
      setShowToast(false);
    }
    if (filesSelectedForUploadRef && filesSelectedForUploadRef.current) {
      filesSelectedForUploadRef.current.value = '';
    }
  };

  const handleClearChat = () => {
    setOutputData('');
    if (setUserInput) {
      setUserInput('');
    }
    if (setShowDownload) {
      setShowDownload(false);
    }
    if (setUploadedFiles) {
      setUploadedFiles(null);
    }
    if (clearSelectedFile) {
      clearSelectedFile();
    }
    setSelectedInstance(newSelectedInstance);
    setShowClearChatWarning(false);
    getInstanceData(newSelectedInstance);
  };
  const handleCancelClearChat = () => {
    setShowClearChatWarning(false);
  };

  const nextStep = () => {
    if (currentStep === 1) {
      if (currentApp?.name === 'Change Communicator') {
        fetchInstances();
      }
      setCurrentStep(currentStep + 1);
      setIsBackClicked(false);
    }
    if (currentStep === 2) {
      setIsBackClicked(false);
      setCurrentStep(currentStep + 1);
      fetchInstances();
    }
    if (currentStep === 3) {
      fetchInstances();
      setIsBackClicked(false);
      // setShowNewInstanceModal(false);
      setCurrentStep(currentStep + 1);
      if (selectedInstance) {
        getInstanceData(selectedInstance);
      }
    }
    //code for llm guard
    if (currentStep === 4) {
      fetchInstances();
      setIsBackClicked(false);
      if (selectedInstance) {
        getInstanceData(selectedInstance);
      }
      setShowNewInstanceModal(false);
    }
  };

  const prevStep = () => {
    if (currentStep === 2) {
      setCurrentStep(currentStep - 1);
      getInstanceData(selectedInstance);
      fetchInstances();
      setIsBackClicked(true);
      setShowToast(false);
      setErrorMessage({
        message: '',
        id: '',
      });
    }
    if (currentStep === 3) {
      getInstanceData(selectedInstance);
      fetchInstances();
      setCurrentStep(currentStep - 1);
      setIsBackClicked(true);
      setShowToast(false);
      setErrorMessage({
        message: '',
        id: '',
      });
    }
    //code for llm guard
    if (currentStep === 4) {
      getInstanceData(selectedInstance);
      fetchInstances();
      setCurrentStep(currentStep - 1);
      setIsBackClicked(true);
      setShowToast(false);
      setErrorMessage({
        message: '',
        id: '',
      });
    }
  };

  const openNewInstanceModal = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    if (chatContents && chatContents.length !== 0) {
      chatbox?.current?.initiateChatWarningModal({
        callback: openNewInstanceModal,
      });
      setInstanceDetails('');
    } else if (outputData && outputData?.length !== 0) {
      setShowClearChatWarning(true);
      setInstanceDetails('');
    } else {
      setCurrentStep(1);
      setShowNewInstanceModal(true);
      setIsEditMode(false);
      setInstanceDetails('');
      setShowToast(false);
      setCloudProvider('azure');
    }
  };

  const openEditInstanceModal = () => {
    setCurrentStep(1);
    setShowNewInstanceModal(true);
    setIsEditMode(true);
    setShowToast(false);
    if (selectedInstance) {
      getInstanceData(selectedInstance);
    }
  };

  const getInstanceData = (instanceId: any) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v2/ui/instance/${instanceId}`
      )
      .then((response) => {
        const data = response.data;
        const knowledgeStatus = data?.response?.knowledgeStatus;
        if (knowledgeStatus === Failed_Status) {
          setKnowledgeErrorId(data?.response?.errorId);
        }
        let selectedInstanceDetails = data?.response;
        if (
          selectedInstanceDetails?.llmModel === GPT35_16K ||
          selectedInstanceDetails?.llmModel === GPT4_32K
        ) {
          selectedInstanceDetails.llmModel = GPT_4O;
        }
        setInstanceDataLoading(false);
        setInstanceDetails(selectedInstanceDetails);
        setLlmCloud(selectedInstanceDetails?.cloudEnvironment);
        setLlmGuardOption(selectedInstanceDetails?.llmGuard ? 'Yes' : 'No');
        setIsInstanceInvalid(selectedInstanceDetails?.isDataInvalid);
        setRefreshKnowledgeDocumentStatus(false);
        setQueryExpansion(selectedInstanceDetails?.queryExpansion);
        let columnArray = [{ value: '' }];
        if (
          selectedInstanceDetails.outputColumns != null &&
          selectedInstanceDetails.outputColumns != ''
        ) {
          selectedInstanceDetails?.outputColumns?.map(
            (output_column: any, index: any) => {
              columnArray[index] = { value: output_column };
            }
          );
          setOutputcolumns([...columnArray, { value: '' }]);
        }
        setKnowledgeDocumentStatus((prev) => {
          if (
            prev === InProgress_Status &&
            knowledgeStatus === Completed_Status
          ) {
            setIsRefreshedClicked(true);
            setTimeout(() => {
              setIsRefreshedClicked(false);
              setShowKnowledgeDocumentStatus(false);
            }, 4000);
          } else {
            setIsRefreshedClicked(false);
          }
          return knowledgeStatus;
        });
        setShowKnowledgeDocumentStatus(true);
      })
      .catch((err) => {
        setRefreshKnowledgeDocumentStatus(false);
        console.error('Error:', err);
      });
  };

  const handleDelete = async () => {
    setShowDeletePopup(false);
    setIsDeleteResponse(false);
    setIsDeleteLoading(true);
    setIsDeleteFailed(false);
    clearTimeout((window as any).deleteFailedTimeout);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/delete-instance/${selectedInstance}`
      );
      if (response.status === 200) {
        setIsDeleteLoading(false);
        setIsDeleteResponse(true);
        fetchInstances();
        setSelectedInstance('');
        setInstanceDetails('');
        if (setOutputData) {
          setOutputData('');
        }
        if (setUserInput) {
          setUserInput('');
        }
        if (setShowDownload) {
          setShowDownload(false);
        }
        if (setUploadedFiles) {
          setUploadedFiles(null);
        }
        if (setChatContents) {
          setChatContents([]);
        }
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (setisUploadingProps) {
          setisUploadingProps(false);
        }
        if (setIsUploadResponseProps) {
          setIsUploadResponseProps(false);
        }
        if (filesSelectedForUploadRef && filesSelectedForUploadRef.current) {
          filesSelectedForUploadRef.current.value = '';
        }
        setDeleteError({ show: false, message: '' });
      } else {
        console.error('Failed to delete instance');
        setIsDeleteLoading(false);
        setIsDeleteFailed(true);
        (window as any).deleteFailedTimeout = setTimeout(() => {
          setIsDeleteFailed(false);
        }, 4000);
      }
    } catch (error: any) {
      console.error('Error:', error);
      const err = error?.response?.data?.error;
      const errID = error?.response?.data?.errorId;
      setShowToast(true);
      setErrorMessage({
        message: err.errorMessage,
        id: errID,
      });
      setDeleteError({
        show: true,
        message: error?.response?.data?.error?.errorMessage,
      });
      setIsDeleteLoading(false);
      setIsDeleteFailed(true);
      (window as any).deleteFailedTimeout = setTimeout(() => {
        setIsDeleteFailed(false);
      }, 4000);
    }
  };
  const canceldeletePopup = () => {
    setShowDeletePopup(false);
    setDeleteError({ show: false, message: '' });
  };

  useEffect(() => {
    const removeSuccessMessage = setTimeout(() => {
      setIsDeleteResponse(false);
    }, 4000);
    return () => clearTimeout(removeSuccessMessage);
  }, [isDeleteResponse]);

  const deleteModal = () => {
    return (
      <div className="clear-chat-warning-container">
        <Modal
          show={showDeletePopup}
          onHide={canceldeletePopup}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Instance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-warning">
              <p>
                This action will delete the selected Instance:{' '}
                {instanceDetails.name}. Are you sure you want to delete it?
              </p>
            </div>
          </Modal.Body>

          <Modal.Footer>
            {userRole && (userRole === ADMIN || userRole === SUPERADMIN) && (
              <Button variant="danger" onClick={handleDelete}>
                Delete
              </Button>
            )}
            <Button variant="secondary" onClick={canceldeletePopup}>
              Cancel
            </Button>
          </Modal.Footer>
          {deleteError.show && (
            <div
              style={{
                paddingTop: '40px',
                width: '50%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Alert
                variant="danger"
                onClose={() => setDeleteError({ show: false, message: '' })}
                dismissible
              >
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>
                  {deleteError.message
                    ? deleteError.message
                    : `Something went wrong. Please try again`}
                </p>
              </Alert>
            </div>
          )}
        </Modal>
      </div>
    );
  };
  const handleRefreshKnowledgeDocument = () => {
    setIsRefreshedClicked(true);
    setRefreshKnowledgeDocumentStatus(true);
    getInstanceData(selectedInstance);
  };

  return (
    <>
      <Form.Group controlId="exampleForm.instancePicker">
        <Form.Label>Select an Instance</Form.Label>
        <div className="icon-dropdown-wrapper">
          <CustomSearchableDropDown
            instances={instances}
            selectedInstance={selectedInstance}
            handleInstanceChange={handleInstanceChange}
          />
          {userRole && (userRole === ADMIN || userRole === SUPERADMIN) && (
            <>
              <Button
                className="icon-edit-button"
                onClick={openEditInstanceModal}
                disabled={
                  !selectedInstance ||
                  knowledgeDocumentStatus === InProgress_Status ||
                  instanceDataLoading
                }
              >
                <FaEdit className="icon-edit" />
              </Button>
              {userRole && (userRole === SUPERADMIN || userRole === ADMIN) && (
                <Button
                  className="knowledge-delete-button"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowDeletePopup(true)}
                  disabled={
                    !selectedInstance ||
                    knowledgeDocumentStatus === InProgress_Status ||
                    instanceDataLoading
                  }
                >
                  <FaTrashAlt className="knowledge-icon-delete" />
                </Button>
              )}
            </>
          )}
        </div>
        {selectedInstance &&
          knowledgeDocumentStatus &&
          showKnowledgeDocumentStatus && (
            <div
              className={
                !isRefreshedClicked &&
                knowledgeDocumentStatus === Completed_Status
                  ? 'hide-knowledge-document-status'
                  : 'knowledge-document-status'
              }
            >
              {knowledgeDocumentStatus === InProgress_Status ? (
                <span className="knowledge-status-label knowledge-status-label-pending">
                  {Pending_Message}
                </span>
              ) : knowledgeDocumentStatus === Failed_Status ? (
                <span className="knowledge-status-label knowledge-status-label-failed">
                  {Failed_Message}
                  <br />
                  {knowledgeErrorId
                    ? Failed_Message_Support_one +
                      knowledgeErrorId +
                      Failed_Message_Support_two
                    : Failed_Message_Support}
                </span>
              ) : (
                isRefreshedClicked && (
                  <span className="knowledge-status-label knowledge-status-label-complete">
                    {Completed_Message}
                  </span>
                )
              )}
              {knowledgeDocumentStatus === InProgress_Status &&
                (!refreshKnowledgeDocumentStatus ? (
                  <IoMdRefresh
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                    onClick={handleRefreshKnowledgeDocument}
                  />
                ) : (
                  <button className="refresh-loader">
                    <ContentLoader />
                  </button>
                ))}
            </div>
          )}
        {isInstanceInvalid && (
          <div className="instance-error">
            <p
              style={{
                fontWeight: 'bold',
                marginBottom: '0.5rem',
              }}
            >
              Oh Snap!
            </p>
            <p style={{ marginBottom: '0' }}>
              Something went wrong with this instance. This instance may not
              give accurate answers. Please try re-creating this instance or
              reach out to the GenAIe team for support.
            </p>
          </div>
        )}
        {showDeletePopup && deleteModal()}
        {isDeleteLoading && (
          <div className="upload-spinner">
            <Spinner animation="border" size="sm" /> Deleting..
          </div>
        )}
        {isDeleteResponse && (
          <div className="upload-msg">
            <Badge bg="danger">Delete Successful</Badge>
          </div>
        )}
        {isDeleteFailed && (
          <div className="upload-msg">
            <Badge bg="danger">Delete Failed</Badge>
          </div>
        )}
        {userRole && (userRole === ADMIN || userRole === SUPERADMIN) && (
          <Form.Label className="create-new-instance-link">
            <a href="javascript:void(0);" onClick={openNewInstanceModal}>
              Create New Instance
            </a>
          </Form.Label>
        )}
      </Form.Group>

      {showNewInstanceModal ? (
        <Modal
          show={showNewInstanceModal}
          onHide={() => {
            setShowNewInstanceModal(false);
            fetchInstances();
            getInstanceData(selectedInstance);
            setIsBackClicked(false);
            setShowToast(false);
            setErrorMessage({
              message: '',
              id: '',
            });
          }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            {isEditMode ? 'Edit Instance' : 'Create New Instance'}
          </Modal.Header>
          <Modal.Body>
            <InstanceGenerator
              currentStep={currentStep}
              projectId={projectId}
              currentAppId={currentApp?.id}
              nextStep={nextStep}
              prevStep={prevStep}
              isBackClicked={isBackClicked}
              setShowNewInstanceModal={setShowNewInstanceModal}
              step2={llmGuardOption === 'Yes' ? true : false}
              step3={step3}
              step4={step4}
              isUploadtemplate={isUploadtemplate}
              isEditMode={isEditMode}
              instanceDetails={instanceDetails}
              selectedInstance={selectedInstance}
              getInstanceData={getInstanceData}
            />
          </Modal.Body>
          {showToast && (
            <ErrorMessage
              errorMessage={errorMessage}
              setShowToast={setShowToast}
              isInsideModal={true}
            />
          )}
        </Modal>
      ) : null}
      {showClearChatWarning && (
        <div className="clear-chat-warning-container">
          <Modal
            show={showClearChatWarning}
            onHide={handleCancelClearChat}
            backdrop="static"
            keyboard={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Clear Chat?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                This action will clear the chat history and create a new
                session. Are you sure you want to clear the chat?
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={handleClearChat}>
                Clear
              </Button>
              <Button variant="secondary" onClick={handleCancelClearChat}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {showDeletePopup && deleteModal()}
    </>
  );
};

export default SelectInstance;
