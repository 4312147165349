export const azure_llm_models_options = [
  ['GPT- 4 TURBO 128K - $$', 'gpt-4-turbo'],
  ['GPT-4o 128K', 'gpt-4o'],
  ['GPT- 4 32K - $$$', 'gpt4-32k'],
  ['GPT- 3.5 16K - $', 'gpt35-16k'],
];
export const azure_recommended_model = 'gpt-4o';
export const AZURE_CLOUD = 'azure';
export const model_recommended = 'recommended';

//Feedback Component Contants
export const ENTER_FEEDBACK_PLACEHOLDER = 'Please enter feedback...';
export const FEEDBACK_THANK_YOU_MSG = 'Thank you for your feedback!';
export const WAS_THIS_HELPFUL = 'Was this helpful?';
export const FEEDBACK_ERROR =
  'Sorry we encountered some issue in sending your feedback!';
export const SUBMIT = 'Submit';
export const THUMBS_UP_ICON = '👍';
export const THUMBS_DOWN_ICON = '👎';

//CaseCopiotConstants
export const UPLOAD_SUCCESS = 'Upload Successful';
export const OPTION_SELECT = 'Choose an option';
export const DATA_FILE = 'Data File';
export const FORMATS_SUPPORTED = 'Formats supported';
export const UPLOAD = 'Uploading';
export const DICTIONARY_FILE = 'Dictionary File';
export const QUESTION = 'Enter your question';
export const SNAP_MESSAGE = 'Something went wrong. Please try again';
export const ERROR_MESSAGE = 'Oh snap! You got an error!';
export const ADVANCED_ERROR_MESSAGE =
  ' Something went wrong with your Advanced settings. Please make sure required fields like query params are not blank.';
export const REFRESHING = 'Refreshing...';
export const FILE_TYPE_ERROR =
  'File type is not supported, please upload file with';

//InstanceGeneratorConstants
export const KNOWLEDGE_IDS = 'Knowledge IDs';

//LlmFineTuneDatasetGeneratorConstants:
export const CREATE_DATASET = 'Create a dataset from document';
export const UPLOAD_DATASET = 'Upload dataset file (CSV/XLSX) to upsample';

// Application constants:
export const USER_STORY_GENERATOR = 'User Story Generator';
//VisionqaFeedback constants:
export const Feed_Back_Message = 'Thank you for the feedback!';

//DocumentOCR constants:
export const File_Format = '.pdf';
export const File_Formats = '.pdf, .jpeg, .jpg, .png';

//tab title
export const UPLOAD_TAB = 'Upload';
export const DOWNLOAD_DOCUMENTATION = 'Download Documentation';

//llm models
export const GPT_4_TURBO = 'gpt-4-turbo';
export const GPT_4O = 'gpt-4o';
export const GPT4_32K = 'gpt4-32k';
export const GPT4_8K = 'gpt4-8k';
export const GPT35_16K = 'gpt35-16k';
export const GPT35_4K = 'gpt35-4k';

//llm guard Scanner types
export const Anonymize = 'Anonymize';
export const BanCode = 'BanCode';
export const BanCompetitors = 'BanCompetitors';
export const BanSubstrings = 'BanSubstrings';
export const BanTopics = 'BanTopics';
export const Code = 'Code';
export const Gibberish = 'Gibberish';
export const InvisibleText = 'InvisibleText';
export const Language = 'Language';
export const PromptInjection = 'PromptInjection';
export const Regex = 'Regex';
export const TokenLimit = 'TokenLimit';
export const Secrets = 'Secrets';
export const Sentiment = 'Sentiment';
export const Toxicity = 'Toxicity';

//For vector DB :
export const WEAVIATE = 'weaviate';
export const PGVECTOR = 'pgvector';
export const OPENSEARCH_SERVERLESS = 'opensearch_serverless';

//For Advance Settings:
export const NORMAL = 'Normal';

//File formats:
export const PDF = '.pdf';
export const DOC = '.doc';
export const DOCX = '.docx';
export const TXT = '.txt';
export const MD = '.md';
export const XLSXFile = '.xlsx';
export const CSV = '.csv';
export const PNG = '.png';
export const JPEG = '.jpeg';
export const JPG = '.jpg';
export const MP3 = '.mp3';
export const MP4 = '.mp4';
export const M4A = '.m4a';
export const WAV = '.wav';
export const MOV = '.mov';
export const ZIP = '.zip';
export const PPT = '.ppt';
export const PPTX = '.pptx';
export const FRM = '.frm';
export const PCO = '.pco';
export const COBOLFile = '.cobol';
export const CTL = '.ctl';
export const CLS = '.cls';

//Current User Role
export const CONTRIBUTOR = 'contributor';
export const ADMIN = 'admin';
export const SUPERADMIN = 'superadmin';

//Select instance knowledge id Status
export const Pending_Status = 'Pending';
export const Completed_Status = 'Completed';
export const Failed_Status = 'Failed';
export const InProgress_Status = 'In Progress';
export const Pending_Message =
  'Your instance is being set up. It may take a short while, depending on documents uploaded.';
export const Completed_Message = 'All set! Your instance is now ready to use.';
export const Failed_Message =
  'On Snap! Your instance could not be configured successfully.';
export const Failed_Message_Support =
  'Please setup the instance again or contact GenAIe team for assistance.';
export const Failed_Message_Support_one =
  'Please setup the instance again or provide error ID: ';
export const Failed_Message_Support_two =
  ' when contacting GenAIe team for assistance.';

export const Failed_Connection_Error =
  'You seem to be offline. Please check your network connection and try again.';

//For instance generator
export const testSteps = 'Test Steps';
export const title = 'Title';
export const expectedOutCome = 'Expected Outcome';
export const userNoteOutputColum1 =
  'Fixed Columns: Title, Test Steps, and Expected Result cannot be edited.';
export const userNoteOutputColum2 = `Add Columns: Click the '+' icon to add new columns. They will appear in the order added.`;
export const userNoteOutputColum3 =
  'Reorder Columns:Drag and drop new columns to rearrange them. The final order will be preserved in the final response.';
export const fileUploadKnowledegenote1 =
  'Note: 1. Formats supported : pdf, doc, docx, txt, xlsx & csv.';
export const fileUploadKnowledegenote2 =
  '2. For accurate results, please upload documents with tables in PDF format only, with a maximum length of 100 pages. Uploading documents in other formats (e.g., DOC, DOCX, TXT) may result in incorrect recognition of table content';
export const customOutputColumnNote1 =
  '1. Non-Editable Columns: The Title, Test Steps, and Expected Result columns cannot be edited.';
export const customOutputColumnNote2 =
  '2. File Upload Experience: These non-editable columns apply only to the "File Upload" experience, meaning test cases must follow the pre-defined format.';
export const customOutputColumnNote3 =
  '3. Chatbot Experience: The "System Template" format will be used for the "Chatbot" experience. If template instructions differ, the chatbot will follow the template, while file uploads will use the specified columns.';
export const KnowledgeNameError =
  'Knowledge Name is a mandatory field. Please enter the required Knowledge Name to proceed further.';
export const TokenLimitError1 = 'Token limit for ';
export const TokenLimitError2 =
  ' exceeded. To fix this issue, please consider changing the LLM model or reducing the knowledge IDs used in the above template.';