import React, { FC, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
  isTokenExpired,
  authenticateUser,
  removeAuthToken,
  updateAuthToken,
} from '../../auth';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import './ErrorPage.css';

interface ErrorPageProps {
  error: number;
}

const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  useEffect(() => {
    if (isTokenExpired()) {
      removeAuthToken();
      authenticateUser();
    }
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (error === 403) {
      updateAuthToken();
    }
  }, [error]);

  return (
    <div className="error-page-container">
      <header>
        <h1>
          <Link to="/">
            <img src="/logo.png" alt="GenAIe Logo" className="genaie-logo" />
          </Link>
        </h1>{' '}
        <div className="back-button-container applications-back">
          <Link to={`/`}>
            <BackIcon /> Back
          </Link>
        </div>
      </header>
      <div className="error-text-container">
        {error === 404 && (
          <>
            <h1>Page not found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
          </>
        )}
        {error === 403 && (
          <>
            <h1>
              Oops! You do not have access to these GPS GenAI application.
            </h1>
            <p>
              Please reach out to your sector’s Nerve Center for more
              information on gaining access.
            </p>
          </>
        )}
        <div>
          <Button
            className="home-button"
            variant="secondary"
            onClick={() => {
              navigate('/');
            }}
          >
            Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
