import React, { useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import './ErrorMessage.css';
import { ADVANCED_ERROR_MESSAGE } from '../../utils/constants';

interface AdvSettingsToastProps {
  setShowAdvancedSettingsAlert: any;
}

const AdvSettingsToast: React.FC<AdvSettingsToastProps> = ({
  setShowAdvancedSettingsAlert,
}) => {
  useEffect(() => {
    if (setShowAdvancedSettingsAlert) {
      const timer = setTimeout(
        () => setShowAdvancedSettingsAlert(false),
        15000
      );
      return () => clearTimeout(timer);
    }
  }, [setShowAdvancedSettingsAlert]);

  return (
    <div className="toast-container">
      <Toast
        onClose={() => setShowAdvancedSettingsAlert(false)}
        show={true}
        delay={15000}
        autohide
        className="bg-danger text-white custom-toast-width"
      >
        <Toast.Header className="bg-danger text-white">
          <strong>Advanced Settings</strong>
        </Toast.Header>
        <Toast.Body>{ADVANCED_ERROR_MESSAGE}</Toast.Body>
      </Toast>
    </div>
  );
};

export default AdvSettingsToast;
