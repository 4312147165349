import { Failed_Connection_Error } from './constants';

interface Chat {
  role: string;
  content: string;
}

type SetStateFunction<T> = React.Dispatch<React.SetStateAction<T>>;
const stream = ({
  requestBody,
  streamCallback,
  doneCallback,
  setShowToast,
  setIsLoading,
  setShowErrMsg,
  llmCloud,
  selectedLlmModel,
  setActivityId,
  activityId,
  signal,
  url,
  currentApp,
  setErrorMessage,
}: {
  requestBody: any;
  streamCallback: Function;
  doneCallback?: Function;
  setShowToast?: any;
  setIsLoading: any;
  setShowErrMsg?: SetStateFunction<string>;
  llmCloud?: string;
  selectedLlmModel?: string;
  setActivityId?: any;
  activityId?: string;
  signal?: AbortSignal;
  url?: string;
  currentApp?: any;
  setErrorMessage?: any;
}) => {
  const token = sessionStorage.getItem('jwtToken');

  let streamingUrl = '';
  setActivityId('');
  if (url) {
    streamingUrl = url;
  } else {
    if (llmCloud === 'azure') {
      if (requestBody?.advance_search_params) {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/prompt/streaming/advance/cached`;
      } else if (requestBody?.messages?.length) {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/prompt/streaming/messages`;
      } else {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/prompt/streaming`;
      }
    }
  }

  if (requestBody?.messages?.length) {
    requestBody.messages = requestBody?.messages?.filter(
      (message: Chat, index: number) =>
        index === 0 || requestBody.messages.length - index < 4
    );
  }
  const isFormData = requestBody instanceof FormData;
  let headers: any = {
    accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };

  if (!isFormData) {
    headers = {
      ...headers,
      'Content-Type': 'application/json',
    };
  }

  fetch(streamingUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(requestBody),
    signal: signal,
  })
    .then((response) => {
      if (response.ok) {
        const reader = response?.body?.getReader();
        setActivityId((response?.headers as any)?.get('x-activityid'));
        const read = () => {
          reader
            ?.read()
            .then(({ done, value }) => {
              if (done) {
                if (doneCallback) {
                  doneCallback();
                }
                return;
              }

              const decoder = new TextDecoder();
              streamCallback(decoder.decode(value));
              read();
            })
            .catch((error) => {
              if (error.name === 'AbortError') {
                console.error('Fetch aborted');
              } else {
                console.error('Fetch error:', error);
              }
            });
        };

        read();
      } else {
        const errID = response?.headers.get('x-api-request-id');
        response.json().then((errorResponse) => {
          const errorMessage =
            errorResponse?.error?.errorMessage ||
            'There is an internal server error. Please try again.';
          setErrorMessage &&
            setErrorMessage({ message: errorMessage, id: errID });
        });
        setShowToast(true);
        setIsLoading(false);
      }
    })
    .catch((err) => {
      const errText = err.toString();
      //  Netwrok error:  Adding specific condition for network or VPN disconnect
      if (errText.includes('TypeError: Failed to fetch')) {
        setErrorMessage({
          message: Failed_Connection_Error,
        });
      }
      setShowToast(true);
      setIsLoading(false);
    });
};

export default stream;
