import React, { useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import './ErrorMessage.css';

interface ErrorMessageProps {
  errorMessage: any;
  setShowToast: any;
  isInsideModal?: boolean;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  errorMessage,
  setShowToast,
  isInsideModal,
}) => {
  useEffect(() => {
    if (setShowToast) {
      const timer = setTimeout(() => setShowToast(false), 15000);
      return () => clearTimeout(timer);
    }
  }, [setShowToast]);

  return (
    <div className="toast-container">
      <Toast
        onClose={() => setShowToast(false)}
        show={true}
        delay={15000}
        autohide
        className={`bg-danger text-white ${isInsideModal ? 'modal-toast' : 'regular-toast'} custom-toast-width`}
      >
        <Toast.Header className="bg-danger text-white close-icon"></Toast.Header>
        <Toast.Body>
          {errorMessage && errorMessage?.message
            ? `${errorMessage?.message}`
            : 'Something went wrong. Please try again'}
          {errorMessage && errorMessage?.id && (
            <div style={{ fontSize: '14px' }}>
              Please provide Error ID: <b>{errorMessage?.id}</b> when contacting
              GenAIe team for assistance.
            </div>
          )}
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default ErrorMessage;
