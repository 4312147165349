/* eslint-disable react/display-name */
import {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
} from 'react';
import {
  Form,
  Button,
  Modal,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { AiOutlineSend } from 'react-icons/ai';
import { FaRegStopCircle } from 'react-icons/fa';
import { RiChatDeleteLine } from 'react-icons/ri';
import { Chat } from '../../interfaces';
import DownloadButton from '../DownloadButton';
import ContentLoader from '../Loader/contentLoader';
import './Chatbox.css';
import { MouseEventHandler } from 'react';
import UserContext from '../../context/UserContext';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Completed_Status } from '../../utils/constants';
import { LuRefreshCw } from 'react-icons/lu';
import { LuCopy } from 'react-icons/lu';
import { LuCopyCheck } from 'react-icons/lu';
import { useParams } from 'react-router-dom';
import { Appl } from '../../interfaces';

const Chatbox = forwardRef(
  (
    {
      submitPrompt,
      downloadButtonOptions,
      chatClearCallback,
      selectedModel,
      isMultiplePrompts,
      feedBackProperties,
      useMarkdownFormat,
      handleAbortStream,
      handleRegenerate,
    }: {
      submitPrompt: MouseEventHandler<HTMLButtonElement>;
      downloadButtonOptions?: any;
      chatClearCallback: Function;
      selectedModel?: any;
      currentAppName?: any;
      isMultiplePrompts?: Boolean;
      feedBackProperties?: any;
      useMarkdownFormat?: boolean;
      handleAbortStream?: MouseEventHandler<HTMLButtonElement>;
      handleRegenerate?: Function;
    },
    ref: any
  ) => {
    const chatWrapper = useRef<null | HTMLPreElement>(null);
    const { showDownload, selectedDownloadInstance } =
      downloadButtonOptions ?? {};
    const [showClearChatWarning, setShowClearChatWarning] =
      useState<boolean>(false);
    const [clearChatCallback, setClearChatCallback] = useState<any>(null);
    const [copyText, setCopyText] = useState<boolean>(false);
    const [showFloatableDiv, setShowFloatableDiv] = useState<boolean>(false);
    const chatElementRef = useRef(null);
    const chatElementWithoutMarkDownRef = useRef(null);

    const userContext = useContext(UserContext) || {
      selectedInstance: '',
      useAdvancedSettings: false,
      setUseAdvancedSettings: () => {},
      chatContents: [],
      setChatContents: () => {},
      userInput: '',
      setUserInput: () => {},
      isLoading: false,
      setIsLoading: () => {},
      llmGuardOption: '',
      knowledgeDocumentStatus: '',
      setKnowledgeDocumentStatus: () => {},
      enableChatRegeneration: false,
    };

    const {
      selectedInstance,
      useAdvancedSettings,
      setUseAdvancedSettings,
      chatContents,
      setChatContents,
      userInput,
      setUserInput,
      isLoading,
      setIsLoading,
      llmGuardOption,
      knowledgeDocumentStatus,
      setKnowledgeDocumentStatus,
      enableChatRegeneration,
    } = userContext;

    useEffect(() => {
      chatWrapper?.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    useEffect(() => {
      chatWrapper?.current?.scrollTo({
        top: chatWrapper?.current?.scrollHeight,
        behavior: 'smooth',
      });
    }, [userInput, chatContents]);

    const initiateChatWarningModal = ({
      callback,
      prop,
    }: {
      callback: Function;
      prop: any;
    }) => {
      setShowClearChatWarning(true);
      setClearChatCallback({ callback, prop });
    };

    const handleAbort = (e: any) => {
      handleAbortStream && handleAbortStream(e);
      setIsLoading(false);
      // setChatContents([]);
    };

    const clearChat = () => {
      setChatContents([]);
      setUserInput('');
      setIsLoading(false);
      chatClearCallback();
      if (clearChatCallback) {
        const { callback, prop } = clearChatCallback;

        callback(prop, true);
      }
      setClearChatCallback(null);
      setShowClearChatWarning(false);
    };

    const cancelClearChat = () => {
      setUseAdvancedSettings(!useAdvancedSettings);
      setShowClearChatWarning(false);
    };

    useImperativeHandle(ref, () => ({
      initiateChatWarningModal,
    }));
    const userInputRef = useRef<HTMLTextAreaElement>(null);
    const [userInputNewLinecount, setUserInputNewLineCount] = useState(0);

    const userInputChange = (event: any) => {
      let pattern = /^[a-zA-Z0-9]*$/;
      if (pattern.test(userInput[userInput.length - 1])) {
        setUserInputNewLineCount(0);
      }
      setUserInput(event?.target?.value);
    };
    const handleEnterAndNewLinecheck = (event: any) => {
      //Enter is pressed and there is userInput and Shiftkey is not pressed query is submitted
      if (userInputRef.current) {
        userInputRef.current.style.height = 'auto';
        userInputRef.current.style.height = `${userInputRef.current.scrollHeight}px`;
      }
      if (event.keyCode === 13 && userInput && !event.shiftKey) {
        submitPrompt(event);
        setUserInputNewLineCount(0);
      }
      //Enter is pressed and userinput is empty and no shiftkey will do nothing
      if (event.keyCode === 13 && !userInput && !event.shiftKey) {
        event.preventDefault();
        setUserInputNewLineCount(0);
        return;
      }
      if (event.keyCode === 13 && event.shiftKey) {
        // Add new line on shift + enter
        if (userInputNewLinecount > 2) {
          event.preventDefault();
          return;
        } else {
          const previousUserInput = userInput + '\n';
          setUserInputNewLineCount(userInputNewLinecount + 1);
          setUserInput(previousUserInput);
        }
      }
    };
    const handleCopyJSON = (e: any, index: any) => {
      if (useMarkdownFormat && chatElementRef.current) {
        copyInnerHTML(chatElementRef.current, index);
      } else {
        if (chatElementWithoutMarkDownRef.current) {
          copyInnerHTML(chatElementWithoutMarkDownRef.current, index);
        }
      }
    };

    const copyInnerHTML = (element: HTMLElement, index: number) => {
      const html = element.innerHTML;
      const text = element.innerText;
      const inputItems = {
        'text/plain': new Blob([text], { type: 'text/plain' }),
        'text/html': new Blob([html], { type: 'text/html' }),
      };
      const clipBoardItem = new ClipboardItem(inputItems);

      //comented for future use

      // const type = 'text/html';
      // const blob = new Blob([html], { type });
      // const data = [new ClipboardItem({ [type]: blob })];

      navigator.clipboard.write([clipBoardItem]).then(
        () => {
          setCopyText(true);
          setShowFloatableDiv(true);
          chatContents[index].copyText = true;
          setTimeout(() => {
            chatContents[index].copyText = false;
            setCopyText(false);
            setShowFloatableDiv(false);
          }, 4000);
        },
        (err) => {
          console.error('Failed to copy HTML to clipboard', err);
        }
      );
    };
    useEffect(() => {
      if (userInputRef.current) {
        userInputRef.current.style.height = 'auto';
        userInputRef.current.style.height = `${userInputRef.current.scrollHeight}px`;
      }
    }, [userInput]);
    return (
      <div>
        <div className="chatbox-container p-2">
          <pre className="chat-wrapper" ref={chatWrapper}>
            {chatContents.map((chat: Chat, index: any) => (
              // <div className={chat?.role === 'user' ? 'text-start' : 'text-end'}>
              <div
                key={index}
                className={
                  chat?.role === 'user'
                    ? 'user-chat individual-chatbox'
                    : 'assistant-chat individual-chatbox'
                }
              >
                <div>
                  <span className="fw-bold capitalize">{`${chat?.role === 'user' ? 'User' : 'GenAIe'}: `}</span>{' '}
                  {chat?.role === 'assistant' && useMarkdownFormat ? (
                    <div
                      className="chatbox-markdown"
                      ref={chatElementRef}
                      id="chat-response"
                    >
                      <Markdown remarkPlugins={[remarkGfm]}>
                        {chat?.content.toString()}
                      </Markdown>
                    </div>
                  ) : (
                    <div
                      className="chat-response"
                      ref={chatElementWithoutMarkDownRef}
                    >
                      {chat?.content
                        .toString()
                        .split(/\n/)
                        .map((text: any, i: any) => {
                          return text.match(/\[.*\]\(.*\)/g) ? (
                            <Markdown key={i}>{text}</Markdown>
                          ) : (
                            <p>{text}</p>
                          );
                        })}
                    </div>
                  )}
                  {chat?.role === 'assistant' &&
                    enableChatRegeneration &&
                    !isLoading &&
                    index === chatContents.length - 1 && (
                      <div className="regenerate-btn-container">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            copyText ? (
                              <Tooltip>Copied</Tooltip>
                            ) : (
                              <Tooltip>Copy</Tooltip>
                            )
                          }
                        >
                          <Button
                            type="button"
                            className="regenerate-btn regenerate-icon copy-text"
                            onClick={(e: any) => handleCopyJSON(e, index)}
                          >
                            {chatContents[index].copyText ? (
                              <>
                                {' '}
                                <LuCopyCheck className="regenerate-icon" />
                                <span className="copy-message">
                                  Copied!
                                </span>{' '}
                              </>
                            ) : (
                              <LuCopy className="regenerate-icon" />
                            )}
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Re-generate</Tooltip>}
                        >
                          <Button
                            className="regenerate-btn"
                            onClick={() =>
                              handleRegenerate &&
                              handleRegenerate(
                                chatContents[chatContents.length - 1].content
                              )
                            }
                          >
                            <LuRefreshCw className="regenerate-icon" />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}
                  {chat?.role === 'assistant' && chat?.images && (
                    <Row className="image-rendering">
                      {chat?.images.map((image: any, index: number) => (
                        <Col key={index} xs={12} md={4} className="mb-3">
                          <div className="image-div">
                            <img
                              className="img-style"
                              src={image}
                              alt={image?.img_name}
                            />
                            {/* <p className="img-name-style">
                              {decodeURI(image?.img_name)}
                            </p> */}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                  {chat?.role === 'assistant' &&
                    enableChatRegeneration &&
                    !isLoading &&
                    index !== chatContents.length - 1 && (
                      <div className="regenerate-btn-container">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            copyText ? (
                              <Tooltip>Copied</Tooltip>
                            ) : (
                              <Tooltip>Copy</Tooltip>
                            )
                          }
                        >
                          <Button
                            type="button"
                            className="regenerate-btn"
                            onClick={(e: any) => handleCopyJSON(e, index)}
                          >
                            {chatContents[index].copyText ? (
                              <>
                                {' '}
                                <LuCopyCheck className="regenerate-icon" />
                                <span className="copy-message">
                                  Copied!
                                </span>{' '}
                              </>
                            ) : (
                              <LuCopy className="regenerate-icon" />
                            )}
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}
                  {chat?.role === 'assistant' &&
                    !enableChatRegeneration &&
                    !isLoading && (
                      <div className="regenerate-btn-container">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            copyText ? (
                              <Tooltip>Copied</Tooltip>
                            ) : (
                              <Tooltip>Copy</Tooltip>
                            )
                          }
                        >
                          <Button
                            type="button"
                            className="regenerate-btn"
                            onClick={(e: any) => handleCopyJSON(e, index)}
                          >
                            {chatContents[index].copyText ? (
                              <>
                                {' '}
                                <LuCopyCheck className="regenerate-icon" />
                                <span className="copy-message">
                                  Copied!
                                </span>{' '}
                              </>
                            ) : (
                              <LuCopy className="regenerate-icon" />
                            )}
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}
                </div>
                {showDownload && chat?.role === 'assistant' && (
                  <div className="download-button-container">
                    <DownloadButton
                      instanceId={selectedDownloadInstance}
                      outputData={chat?.content}
                    />
                  </div>
                )}
              </div>
            ))}
          </pre>
          {showFloatableDiv && (
            <div className="copied_text">Copied To Clipboard!</div>
          )}
          <Form>
            <Form.Group
              className="chat-user-input"
              controlId="exampleForm.userInput"
            >
              <Form.Control
                as="textarea"
                placeholder="Enter your prompt"
                className="user-prompt-input"
                ref={userInputRef}
                value={userInput}
                rows={1}
                onChange={(e) => userInputChange(e)}
                onKeyDown={(e) => handleEnterAndNewLinecheck(e)}
                draggable={false}
                disabled={
                  knowledgeDocumentStatus !== Completed_Status ||
                  isLoading ||
                  !selectedInstance
                }
              />
              <div className="user-story-generator-submit-container">
                {isLoading ? (
                  <Button
                    className="user-story-generator-submit primary-btn-style"
                    disabled={true}
                  >
                    <ContentLoader />
                  </Button>
                ) : (
                  <Button
                    className="user-story-generator-submit primary-btn-style"
                    type="submit"
                    onClick={submitPrompt}
                    disabled={!userInput?.length}
                  >
                    <AiOutlineSend />
                  </Button>
                )}
                {isLoading &&
                chatContents &&
                chatContents[chatContents.length - 1].content.length &&
                llmGuardOption === 'No' ? (
                  <Button
                    className="clear-chat-btn"
                    variant="danger"
                    type="button"
                    onClick={handleAbort}
                    disabled={
                      chatContents &&
                      !chatContents[chatContents.length - 1].content.length
                    }
                  >
                    <FaRegStopCircle size="1.25rem" />
                  </Button>
                ) : (
                  <Button
                    className="clear-chat-btn"
                    variant="danger"
                    onClick={() => {
                      setShowClearChatWarning(true);
                    }}
                    disabled={!(userInput?.length || chatContents?.length)}
                  >
                    <RiChatDeleteLine size="1.25rem" />
                  </Button>
                )}
              </div>
            </Form.Group>
          </Form>
        </div>
        {showClearChatWarning && (
          <div className="clear-chat-warning-container">
            <Modal
              show={showClearChatWarning}
              onHide={cancelClearChat}
              backdrop="static"
              keyboard={true}
            >
              <Modal.Header closeButton>
                <Modal.Title>Clear Chat?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  This action will clear the chat history and create a new
                  session. Are you sure you want to clear the chat?
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={clearChat}>
                  Clear
                </Button>
                <Button variant="secondary" onClick={cancelClearChat}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </div>
    );
  }
);

export default Chatbox;
